// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-agb-2019-05-09-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/app-agb-2019-05-09.js" /* webpackChunkName: "component---src-pages-app-agb-2019-05-09-js" */),
  "component---src-pages-app-consentform-2019-05-09-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/app-consentform-2019-05-09.js" /* webpackChunkName: "component---src-pages-app-consentform-2019-05-09-js" */),
  "component---src-pages-app-faq-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/app-faq.js" /* webpackChunkName: "component---src-pages-app-faq-js" */),
  "component---src-pages-app-privacypolicy-2019-05-09-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/app-privacypolicy-2019-05-09.js" /* webpackChunkName: "component---src-pages-app-privacypolicy-2019-05-09-js" */),
  "component---src-pages-impressum-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mail-bestaetigen-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/mail-bestaetigen.js" /* webpackChunkName: "component---src-pages-mail-bestaetigen-js" */),
  "component---src-pages-passwort-zuruecksetzen-js": () => import("/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/src/pages/passwort-zuruecksetzen.js" /* webpackChunkName: "component---src-pages-passwort-zuruecksetzen-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/.cache/data.json")

