module.exports = [{
      plugin: require('/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-131835509-1"],"gtagConfig":{"anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["IBM Plex Sans:400,500,600,700"]}},
    },{
      plugin: require('/Users/Daniel/Daniel/_BRIVE/brive-lp-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
